import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import UtilsService from './UtilsService';
import PageLoadService from './PageLoadService';
import type {
    TUserABTests,
    TVariant,
    TestNames
} from '../ab-tests/types/ab-test-types';
import { ABTestToolUtils } from './ABTestToolServiceUtils.v2';
import {
    FALLBACK_VARIANT_VALUE,
    TEST_PROPERTIES_SEPARATOR
} from '../../static/ab-test-shared-constants';
import { getPageTests, isTestExist } from '../../static/ab-test-shared-utils';
import { AB_TESTS_CONFIG } from '../../static/tests-config';
import type { TRunningTests } from '../ab-tests/types/running-tests-config';
import StatsigService from './StatsigService';
import { trackError } from '../utils/error.utils';

class ABTestToolService {
    private userAbTests: TUserABTests = {};
    private userPageTests: Record<string, TUserABTests> = {};
    constructor() {
        const isDev = UtilsService.isBrowser() && UtilsService.isDev();
        if (isDev) {
            ABTestToolUtils.handleDevCookie();
        }
        this.updateUserAbTestsFromCookie();
    }

    private updateUserAbTestsFromCookie() {
        if (!UtilsService.isBrowser()) return;
        const userTests = ABTestToolUtils.getUserTestsFromCookie();
        this.userAbTests = userTests.reduce((userTests: TUserABTests, test) => {
            const [testName, variant] = test.split(TEST_PROPERTIES_SEPARATOR);
            const isFallbackVariant = variant === FALLBACK_VARIANT_VALUE;
            if (!isTestExist(testName, variant, isFallbackVariant)) {
                return userTests;
            }

            const testConfig = AB_TESTS_CONFIG[testName]!;
            const testProperties = ABTestToolUtils.getTestProperties(
                testName,
                variant as TVariant,
                isFallbackVariant
            );

            userTests[testName] = {
                ...testProperties,
                ...(testConfig.pages
                    ? { pages: testConfig.pages }
                    : { is_global_test: true })
            };

            return userTests;
        }, {});
        ABTestToolUtils.updateUserExposedTests(this.userAbTests);
    }

    async fireAbTestAnalytics(path: string) {
        if (PageLoadService.isUserLoggedIn) return;
        const testsInPage = this.getVariantPerTestInPage(path);
        const exposedTests = ABTestToolUtils.getUserExposedTests();

        for (const testName in testsInPage) {
            if (exposedTests[testName]) {
                continue;
            }

            try {
                await StatsigService.manuallyLogExperimentExposure(testName);
                AnalyticsService.track(
                    AnalyticsEvents.ab_test_marketing_started,
                    {
                        ...this.getMixpanelData(testName as TestNames),
                        ...{ ...testsInPage, [testName]: undefined }
                    }
                );
                exposedTests[testName] = true;
            } catch (error) {
                trackError(error);
                break;
            }
        }

        ABTestToolUtils.setUserExposedTests(exposedTests);
    }

    getUserPageTests(path: string) {
        if (this.userPageTests[path]) {
            return this.userPageTests[path];
        }
        const userPageTests = getPageTests(path, this.userAbTests);
        this.userPageTests[path] = userPageTests;
        return userPageTests;
    }

    getVariantPerTestInPage(path: string): Partial<TRunningTests> {
        const testsInPage = this.getUserPageTests(path);
        const testsInPageNames = Object.keys(testsInPage);
        return testsInPageNames.reduce((variantsPerTests, test) => {
            variantsPerTests[test] = testsInPage[test].variant;
            return variantsPerTests;
        }, {});
    }

    getMixpanelData(testName: TestNames) {
        return {
            test_platform: 'ab_testor',
            ...this.getTestDetails(testName)
        };
    }

    getTestDetails(testName: TestNames) {
        return this.userAbTests[testName];
    }

    getUserABTests() {
        return this.userAbTests;
    }

    getUserFlatABTests() {
        const userTestsNames = Object.keys(this.userAbTests).filter(
            testName => !this.userAbTests[testName].is_not_passed_gate
        );
        return userTestsNames.reduce((userTests, testName) => {
            userTests[testName] = this.userAbTests[testName];
            return userTests;
        }, {});
    }
}

export default new ABTestToolService();
