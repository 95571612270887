import UtilsService from './UtilsService';
import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from './AnalyticsService';
import ABTestToolServiceV2 from './ABTestToolService.v2';
import ABTestToolService from './ABTestToolService';
import { trackError } from '../utils/error.utils';

class PageLoadService {
    isUserLoggedIn: boolean = false;

    handlePageAnalytics = () => {
        this.redirectIfLoggedInUser();
        this.handleFirePageViewAnalytics();
    };

    handleFirePageViewAnalytics = () => {
        this.firePageViewAnalytics();
        this.fireSegmentPageView();
    };

    fireSegmentPageView = () => {
        if (this.isUserLoggedIn) {
            return;
        }
        // window.analytics.page and window.analytics.track add the canonical link value
        // to the event context and not window.location.pathname.
        // Since the value of canonical link is only updated after the page is loaded,
        // we need to wait for the page to load before firing the page view event.
        setTimeout(() => {
            AnalyticsService.reportEvent(
                `${window.location.pathname} Page Viewed`
            );
            AnalyticsService.pageView();
        }, 1);
    };

    firePageViewAnalytics = () => {
        if (this.isUserLoggedIn) {
            AnalyticsService.track(AnalyticsEvents.logged_in_user);
            window.location.href = `${window.location.origin}/app/login`;
            return;
        }
        const pageName = UtilsService.getSignupLandingPageFromLocationPath();
        setTimeout(() => {
            AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.virtual_page_view, {
                page_name: pageName
            });
        }, 0);

        const abTestData =
            (UtilsService.shouldUseABTestToolV2()
                ? ABTestToolServiceV2?.getVariantPerTestInPage(
                      window.location.pathname
                  )
                : ABTestToolService.getMixpanelData(
                      window.location.pathname
                  )) || {};

        AnalyticsService.track(
            AnalyticsEvents.page_view + pageName,
            abTestData
        );
    };

    allowRedirect = () => {
        let shouldAllowRedirect = true;
        const { pathname } = window.location;

        const DO_NOT_REDIRECT_LOGGED_IN_USER_PATHNAMES = [
            '/product-education',
            '/business-personality-test',
            '/terms/terms-of-service',
            '/terms/terms-of-service-original',
            '/terms/privacy',
            '/terms/privacy-original',
            '/free-invoice-templates/hub',
            '/free-invoice-templates/',
            '/free-invoice-templates/doula',
            '/free-invoice-templates/coach',
            '/free-invoice-templates/consultants',
            '/free-invoice-templates/photographers',
            '/free-invoice-templates/graphic-design',
            '/free-invoice-templates/interior-design',
            '/free-invoice-templates/tutors',
            '/free-invoice-templates/cleaners',
            '/free-invoice-templates/designers',
            '/photography-contract-templates',
            '/freelance-consulting-contract-template',
            '/free-contract-templates',
            '/careers',
            '/about',
            '/contact-us',
            '/press',
            '/referrals',
            '/educators',
            '/templates',
            '/lp/referrals-tos',
            '/lp/premium-program',
            '/lp/promotional-terms',
            '/lp/honeybook-pic-time-terms-of-service',
            '/lp/honeybook-adobe-terms-of-service',
            '/lp/zero-transaction-fees-terms-of-service',
            '/lp/boss-vision',
            '/tools/self-employment-tax-calculator',
            '/email-signature',
            '/invoice-template',
            '/mission-statement',
            '/feedback'
        ];

        shouldAllowRedirect = !DO_NOT_REDIRECT_LOGGED_IN_USER_PATHNAMES.some(
            pathPrefix => pathname && pathname.startsWith(pathPrefix)
        );

        return !UtilsService.isInTemplatePreviewPage() && shouldAllowRedirect;
    };

    redirectIfLoggedInUser = () => {
        const shouldRedirect = this.allowRedirect();

        try {
            if (shouldRedirect && window.localStorage) {
                let jstorage = window.localStorage.jStorage;
                if (jstorage) {
                    jstorage = JSON.parse(jstorage);
                    if (
                        jstorage.HB_CURR_USER &&
                        jstorage.HB_API_VERSION &&
                        jstorage.HB_AUTH_TOKEN &&
                        jstorage.HB_AUTH_USER_ID
                    ) {
                        UtilsService.storeInitialUtms();
                        UtilsService.storeCoupon();
                        this.isUserLoggedIn = true;
                        return;
                    }
                }
            }
            this.isUserLoggedIn = false;
        } catch (error) {
            this.isUserLoggedIn = false;
            trackError(error, { message: 'unable to access localStorage' });
        }
    };
}

export default new PageLoadService();
