import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '../../honeybook-ui/HBButton/HBButton';

import './case-study-block-rebrand.scss';

const caseStudyData = {
    transId: 'CaseStudyBlock',
    image: 'marketing_site/testimonials/case-study-jennifer.png',
    logo: 'marketing_site/testimonials/case-study-logo.png',
    link: '/blog/case-study-unique-client-experience'
};

const CaseStudyBlock = ({ caseStudy = caseStudyData }) => {
    const { t } = useTranslation();
    const { transId, image, logo, link } = caseStudy;

    return (
        <FeatureBlockSection customClass="case-study">
            <FeatureBlockContent>
                <div className="case-study__image">
                    <HBMediaElement name={image} />
                    <div className="case-study__member">
                        <p className="case-study__member__name">
                            {t(`${transId}.name`)}
                        </p>
                        <p className="case-study__member__tag">
                            {t(`${transId}.tag`)}
                        </p>
                    </div>
                </div>
                <div className="case-study__text">
                    <HBMediaElement
                        customClass="case-study__logo"
                        name={logo}
                    />
                    <p className="case-study__quote">{t(`${transId}.quote`)}</p>
                    <a className="case-study__link" href={link}>
                        <HBButton
                            buttonStyle="secondary"
                            text={t(`${transId}.learnMore`)}
                            icon="nx-arrow-right-16"
                        />
                    </a>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

CaseStudyBlock.propTypes = {
    caseStudy: PropTypes.object
};

export default CaseStudyBlock;
