"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    mobile_sticky_cta: {
        variants: {
            control: { ab_test_variation: 'control' },
            sticky_cta: {
                ab_test_variation: 'sticky_cta'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    test_manual_exposure: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    adobe_test: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/adobe']
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};
