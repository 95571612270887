import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import NavigateLink from '../../Link/NavigateLink';
import HBLogo from '../../../svg/honeybook-logo.inline.svg';

import './feedback-header.scss';

const FeedbackHeader = ({ isSubmitted, companyLogoUrl, companyName }) => {
    return (
        <div className="feedback-header">
            <NavigateLink link="/" source="client_feedback">
                <HBLogo className="feedback-header__hb-logo" />
            </NavigateLink>

            {!isSubmitted && <div className="feedback-header__spacer"></div>}

            <div className="feedback-header__member-info">
                <HBMediaElement
                    customClass="feedback-header__company-logo"
                    name={companyLogoUrl || ''}
                />
                <HBText
                    customClass="feedback-header__company-name"
                    type="title-s"
                    tag="h2"
                >
                    {companyName}
                </HBText>
            </div>
        </div>
    );
};

export default FeedbackHeader;
