import { useEffect, useMemo } from 'react';
import type { IframeEvents, TemplateIdProps } from './type';
import { useIsBrowser } from '../../hooks/useIsBrowser';
import UtilsService from '../../Services/UtilsService';

export const PublicTemplateIframe = ({ templateId }: TemplateIdProps) => {
    const { isBrowser } = useIsBrowser();

    const iframeUrl = useMemo(() => {
        if (!isBrowser) {
            return '';
        }
        const { protocol, host } = window.location;
        let urlHost = 'generic.portal.co';
        if (UtilsService.isStaging())
            urlHost = host.replace('hb-app-staging', 'hb-cx-staging');
        else if (UtilsService.isDev()) {
            urlHost = 'generic.localhost:3000';
        }
        const cpu = UtilsService.getParameterByName('cpu')!;
        const shouldAddCpuParam = !UtilsService.isProduction() && !!cpu;

        const searchParams = new URLSearchParams({
            ...(shouldAddCpuParam && { cpu }),
            fetch_config_by_id: 'true'
        });
        return `${protocol}//${urlHost}/template-preview/${templateId}?${searchParams.toString()}`;
    }, [templateId, isBrowser]);

    const handlerPerEvent: Record<IframeEvents, (data) => void> = {
        click_use_template({ signupUrl }) {
            if (signupUrl) {
                window.location.href = signupUrl;
            }
        }
    };

    useEffect(() => {
        if (!iframeUrl) {
            return;
        }
        const handler = (event: MessageEvent) => {
            const { origin } = new URL(iframeUrl);
            if (event.origin !== origin) {
                return;
            }
            const iframeEvent = event.data.type;
            if (handlerPerEvent[iframeEvent]) {
                handlerPerEvent[iframeEvent](event.data);
            }
        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, [iframeUrl]);

    return <iframe src={iframeUrl} />;
};
