import * as PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../components/FeatureBlockElements';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ImageTextElement from '../components/ImageTextElement/ImageTextElement';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import ThinkOfSwitchingBlock from '../components/FeatureBlocks/ThinkOfSwitchingBlock/ThinkOfSwitchingBlock';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';
import { TRANSLATION_COMPONENTS } from '../constants/constants';

import '../styles/verticals.scss';
import { getCustomFeatureGridData } from '../utils/custom-feature-grid-data';

const data = {
    generic: {
        featurePropImages: [
            'marketing_site/vertical-lps/streamlined_icon.svg',
            'marketing_site/vertical-lps/showcase_icon.svg',
            'marketing_site/vertical-lps/booked_icon.svg'
        ],
        quotes: [
            {
                image: 'marketing_site/freelance-lp/brandon_ward.jpg',
                text: 'FreelancerPage.quotes.quote1.text',
                name: 'Brandon Ward',
                description: 'FreelancerPage.quotes.quote1.description'
            },
            {
                image: 'marketing_site/freelance-lp/image2.png',
                text: 'FreelancerPage.quotes.quote2.text',
                name: 'Tara Ritchie',
                description: 'FreelancerPage.quotes.quote2.description'
            },
            {
                image: 'marketing_site/freelance-lp/image1.png',
                text: 'FreelancerPage.quotes.quote3.text',
                name: 'Josh LeClair',
                description: 'FreelancerPage.quotes.quote3.description'
            }
        ],
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ]
    },
    venuesPage: {
        ogTags: {
            url: 'https://www.honeybook.com/venue-management-software',
            title: 'Venue CRM Software CRM That Grows Your Business',
            description:
                'Use our venue CRM software for all in one venue booking, scheduling, vendor management, and payments.'
        },
        hero: {
            image: 'marketing_site/VenuePage/venue_hero_2.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/VenuePage/Copy_of_pipeline.png',
            'marketing_site/VenuePage/venue_proposal.png',
            'marketing_site/VenuePage/sellyourvenue_branded_venue.png'
        ],
        faqs: [
            {
                id: 'venueGoodFit'
            },
            {
                id: 'venueTeam',
                answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209044-team-member-roles-in-honeybook" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'venueOthers',
                answer: '#TEXT# <a href="https://www.honeybook.com/blog/see-canyon-fruit-ranch-wedding-venue-business" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'venueServiceCharges'
            },
            {
                id: 'venueBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a>#TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a>#TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    freelancerPage: {
        ogTags: {
            url: 'https://www.honeybook.com/crm-for-freelancers',
            title: 'CRM for Freelancers: Fully Customizable',
            description:
                "Manage and grow your business with HoneyBook's CRM for freelancers. Manage client inquiries, payments and more."
        },
        hero: {
            image: 'marketing_site/freelance-lp/freelancer_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'freelancerOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'freelancerCRM',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'freelancerInvoice',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'freelancerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'freelancerOthers'
            },
            {
                id: 'freelancerTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT# <#TEXT# href="/product-education">#LINK_TEXT#</#TEXT#> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    cinematographersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-cinematography',
            title: 'All-in-One Software for Cinematographers',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for cinematographers. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Online_coaches.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'cinematographerOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cinematographerBetter',
                answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cinematographerInvoices',
                answer: '#TEXT# <a href="/invoice">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cinematographerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cinematographerInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT# <#TEXT# href="/product-education">#LINK_TEXT#</#TEXT#> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    allInOnePhotographersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-photographers',
            title: 'All-in-One Software for Photographers',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for photographers. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/photo_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/vertical-lps/photographers/1_photo_pipeline.png',
            'marketing_site/vertical-lps/photographers/2_photo_workspace.png',
            'marketing_site/vertical-lps/photographers/3_photo_brand.png'
        ],
        faqs: [
            {
                id: 'allInOnePhotographerOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'allInOnePhotographerBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'allInOnePhotographerInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'allInOnePhotographerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'allInOnePhotographerInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    eventRentalsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-event-rental-companies',
            title: 'All-in-One Software for Event Rental Companies',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for event rental companies. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/freelancer_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'eventRentalsOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventRentalsBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventRentalsInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventRentalsHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventRentalsOthers'
            },
            {
                id: 'eventRentalsTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    yogaPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-yoga-studios',
            title: 'All-in-One Software for Yoga Studios',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for yoga studios. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/woman_generic_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'yogaOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'yogaBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'yogaInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'yogaHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'yogaTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    teachersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-teachers',
            title: 'All-in-One Software for Teachers',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for teachers. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hero_print.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'teachersOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'teachersBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'teachersInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'teachersHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'teachersTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    officiantsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-officiants',
            title: 'All-in-One Software for Officiants',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for officiants. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/accounting.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'officiantsOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'officiantsBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'officiantsInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'officiantsHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    privatePracticePage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-private-practice',
            title: 'All-in-One Software for Private Practice',
            description: `Manage invoices, contracts, questionnaires, and patient communication with the best CRM for private practice. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hero_print.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'privatePracticeOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'privatePracticeBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'privatePracticeInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'privatePracticeHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'privatePracticeTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    lawyersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-lawyers',
            title: 'All-in-One Software for Lawyers',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for lawyers. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hero_print.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'lawyerOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lawyerBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lawyerInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lawyerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lawyerTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    groupPracticePage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-group-practice',
            title: 'All-in-One Software for Group Practice',
            description: `Manage invoices, contracts, questionnaires, and patient communication with the best CRM for a group practice. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hero_print.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'groupPracticeOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'groupPracticeBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'groupPracticeInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'groupPracticeHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'groupPracticeTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    businessConsultantPage: {
        ogTags: {
            url: 'https://www.honeybook.com/crm-for-consultants',
            title: 'CRM Software for Consulting: All-in-One',
            description: `Grow your business with HoneyBook's CRM software for consulting. Now you can keep all client communication, contracts, payments, and invoices in one place.`
        },
        hero: {
            image: 'marketing_site/business-consultant-lp/consultant_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/business-consultant-lp/Copy_of_module2_freelance_workspace.png',
            'marketing_site/business-consultant-lp/Copy_of_module_3_freelancer_workspace.png',
            'marketing_site/business-consultant-lp/Copy_of_module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'bizConsultantHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'bizConsultantOthers'
            },
            {
                id: 'bizConsultantTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    doulaPage: {
        ogTags: {
            url: 'https://www.honeybook.com/doula-business-software',
            title: 'Software for Doulas CRM',
            description:
                'Use our CRM management software for doulas to communicate with new mothers. Track inquiries, onboard clients, send contracts, and accept payments easily in one place.'
        },
        hero: {
            image: 'marketing_site/doula-lp/doula_hero_image.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/doula-lp/doula_forms_module2.png',
            'marketing_site/doula-lp/doula_workspace_module_3.png',
            'marketing_site/doula-lp/doula_mobile_module_4.png'
        ],
        faqs: [
            {
                id: 'doulaOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'doulaSuccess'
            },
            {
                id: 'doulaBenefits',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'doulaContract',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'help'
            },
            {
                id: 'others'
            },
            {
                id: 'intakeForm',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3050322-using-the-honeybook-contact-form" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'questionnaire',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/group1/Group_47.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    weddingPhotographersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-wedding-photographers',
            title: 'All-in-One Software for Wedding Photographers',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for wedding photographers. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/photo_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'weddingPhotographerOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerLook',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPhotographerTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    estheticiansPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-estheticians-and-makeup-artists',
            title: 'All-in-One Software for Estheticians & Makeup Artists',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for beauty & makeup professionals. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hero_makeup.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'estheticianOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'estheticianBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'estheticianInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'estheticianHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'estheticianInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    hairStylistsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-hair-stylists',
            title: 'All-in-One Software for Hair Stylists',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for hair stylists & beauty professionals. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/hair-stylist-hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'hairStylistOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'hairStylistBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'hairStylistInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'hairStylistHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'hairStylistInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    photoBoothsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-photo-booth-rental-companies',
            title: 'All-in-One Software for Photo Booth Rental Companies',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for photo booth rental companies. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/new_hero_collaborating.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'photoBoothOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</#TEXT#> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photoBoothBetter',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photoBoothInvoices',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photoBoothHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photoBoothOthers'
            },
            {
                id: 'photoBoothInvoiceTemplates'
            },
            {
                id: 'HBBusy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBTutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    eventPlannersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-event-planners',
            title: 'Event Planning Contracts and CRM Software',
            description:
                'HoneyBook offers event planning contracts, invoices, payments and more all in one place. Discover why event planners love HoneyBook'
        },
        hero: {
            image: 'marketing_site/event-planner-lp/event_planner_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/event-planner-lp/event_planner_templates_module2.png',
            'marketing_site/event-planner-lp/event_planner_workspace_module3.png',
            'marketing_site/event-planner-lp/planner_mobile_module_4.png'
        ],
        faqs: [
            {
                id: 'eventHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventDifferent'
            },
            {
                id: 'eventOthers'
            },
            {
                id: 'eventQuestionnaire',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'eventTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    weddingPlannersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/all-in-one-software-for-event-and-wedding-planners',
            title: 'All-in-One Software for Event & Wedding Planners',
            description: `Manage invoices, contracts, questionnaires, and client communication with the best CRM for event and wedding planners. Try HoneyBook free for 7 days.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/new_hero_collaborating.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'weddingPlannerOverview'
            },
            {
                id: 'weddingPlannerStart',
                answer: '#TEXT# <a href="https://www.honeybook.com/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPlannerTypes'
            },
            {
                id: 'weddingPlannerPrep',
                answer: '#TEXT# <a href="https://www.honeybook.com/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'weddingPlannerDesign'
            },
            {
                id: 'weddingPlannerWork'
            },
            {
                id: 'weddingPlannerNeed'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    onlineCoachingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-online-coaching',
            title: 'Online Coaching Management Software',
            description: `Get organized with HoneyBook's online coaching management software. Manage all client communication, contracts, and payments all from one place.`,
            noIndex: true
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Online_coaches.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'onlineCoachingOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'onlineCoachingTools'
            },
            {
                id: 'onlineCoachingForm',
                answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209082-customizing-the-honeybook-contact-form" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'onlineCoachingContractTemplates',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    dogTrainersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-dog-trainers',
            title: 'Dog Training Business Software CRM',
            description:
                'Dog training business software keeps you efficient without needing to switch tools for each task. Easily manage payments and client communication.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Dog_Training.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'dogTrainersOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'dogTrainersComparison',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'dogTrainersGood',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'dogTrainersHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'dogTrainersOthers'
            },
            {
                id: 'dogTrainersInvoiceTemplates'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    visualArtistsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-visual-artists',
            title: 'CRM for Visual Artists (Management Software)',
            description: `HoneyBook's CRM for visual artists streamlines your business. Quickly send invoices, contracts, and proposals so clients can hire you in one step.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Visual_Artists.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'visualArtistOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'visualArtistComparison',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'visualArtistGood',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'visualArtistHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'visualArtistOthers'
            },
            {
                id: 'visualArtistInvoiceTemplate'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    PRPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-pr-companies',
            title: 'Public Relations CRM Software',
            description: `Keep your PR company organized with HoneyBook's public relationships CRM software. Easily manage payments, questionnaires, and client communication.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_PR.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'PROverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'PRComparison',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'PRGood',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'PRHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'PROthers'
            },
            {
                id: 'PRInvoiceTemplates'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    bandManagersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-band-managers',
            title: 'Band Management Software CRM',
            description:
                'All-in-one band management software CRM for more efficient communication and booking handling. Easily manage clients, invoices, and questionnaires.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Band_Managers.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'bandGood',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'bandTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'bandInvoiceTemplates',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'bandContracts',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'bandForm'
            },
            {
                id: 'bandOthers'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    webDesignersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-web-designers',
            title: 'CRM for Web Designers (Management Software)',
            description: `Grow your web design business. HoneyBook's CRM for web designers means all of your client inquiries, communication, contracts, and payments in one place.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_web_Designers.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'webDesignerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'webDesignerOthers'
            },
            {
                id: 'webDesignerTemplates',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'webDesignerOtherTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    agenciesPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-agencies',
            title: 'CRM for Agencies',
            description: `Work on your own terms when you use HoneyBook's CRM software for agencies. Manage inquiries and projects on the go and streamline client communication.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Agencies.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'agencyOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'agencyComparison',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'agencyGood',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'agencyHelp'
            },
            {
                id: 'agencyOthers'
            },
            {
                id: 'agencyInvoiceTemplates'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    franchisePage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-franchises',
            title: 'CRM for Franchises - All-in-One Solution',
            description: `HoneyBook’s CRM for franchises keeps you organized and combines multiple tools in one place. Stand out from the competition and build client trust`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Verticalized_LPs_Franchise.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'franchiseOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'franchiseComparison',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'franchiseGood',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'franchiseHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'franchiseOthers'
            },
            {
                id: 'franchiseInvoiceTemplates'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    marketingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/marketing-consultant-software',
            title: 'CRM Software for Marketing Consultants: All-in-One',
            description:
                'Improve your client management with CRM software for marketing consultants. Organize client communication, contracts, and payments in one place. '
        },
        hero: {
            image: 'marketing_site/vertical-lps/marketingConsultant.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'marketersOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'marketersTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'marketersProposalTemplates',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'marketersContracts',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'marketersHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'marketingOthers'
            },
            {
                id: 'marketingTemplates'
            },
            {
                id: 'marketingOtherTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    designPage: {
        ogTags: {
            url: 'https://www.honeybook.com/crm-for-designers',
            title: 'CRM for Designers: All-in-One Software',
            description:
                'Improve your client management with our CRM for designers. Organize client communication, contracts, and payments all in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/graphic_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'designerHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designerOthers'
            },
            {
                id: 'designerQuestionnaire',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designerTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/overview.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/agreement.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/design_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    graphicDesignPage: {
        ogTags: {
            url: 'https://www.honeybook.com/crm-for-graphic-designers',
            title: 'Graphic Design Workflow Management - CRM',
            description:
                'Manage your graphic design business with our graphic design workflow management software. Organize client communication, contracts, and payments all in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/graphic_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'designHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designOthers'
            },
            {
                id: 'designQuestionnaire',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateBlockLinks: {
            invoiceTemplates: '/free-invoice-templates/graphic-design'
        },
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/overview.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/agreement.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/design_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    landscapingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/crm-for-landscapers',
            title: 'Landscaping CRM for Landscapers and Lawncare Pros',
            description:
                'Improve your client management with Landscaping CRM software for landscaping business. Organize client communication, contracts, and payments in one place.'
        },
        hero: {
            image: '/marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_1_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_2.png'
        ],
        faqs: [
            {
                id: 'landscapingHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'landscapingOthers'
            },
            {
                id: 'landscapingTemplates'
            },
            {
                id: 'landscapingContracts',
                answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2548913-video-tutorial-creating-a-contract-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_3.png',
            contractTemplates:
                'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_4.png',
            brocureTemplates:
                'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_5.png',
            proposalTemplates:
                'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_6.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_7.png'
        },
        emailTemplates:
            'marketing_site/vertical-lps/Vertical%205%20_%20Landscapers/3%20_%20Final%20Deliverables/SEO_landscapersr_8.png'
    },
    contractorPage: {
        ogTags: {
            url: 'https://www.honeybook.com/contractor-billing-software',
            title: 'Construction Billing Software for Contractors',
            description:
                'Grow your business with an all in one construction billing software for contractors. Manage invoices, client communication, and contracts from one platform'
        },
        hero: {
            image: 'marketing_site/vertical-lps/contractor_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'contractorHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'contractorOthers'
            },
            {
                id: 'contractorTemplates'
            },
            {
                id: 'contractorOtherTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%205%20-%20General/Copy_of_Group_48.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    educationPage: {
        ogTags: {
            url: 'https://www.honeybook.com/tutor-software',
            title: 'Tutor Management Software CRM',
            description:
                'Grow your business with tutor management software for lesson scheduling, communication with students, and real-time payment tracking all in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/tutor_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'educationOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'educationTypes',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT_#</a> #TEXT#'
            },
            {
                id: 'educationTemplates',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT_#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%205%20-%20General/Copy_of_Group_48.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    cateringPage: {
        ogTags: {
            url: 'https://www.honeybook.com/catering-management-software',
            title: 'Catering Management Software - All-in-one solution',
            description: `Manage and grow your business with HoneyBook's catering management software. Manage client inquiries, communication, payments, and more, all in one place.`
        },
        hero: {
            image: '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_1_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/catering-lp/catering_templates_module2.png',
            'marketing_site/catering-lp/catering_module3_workspace.png',
            'marketing_site/catering-lp/catering_mobile_module4.png'
        ],
        faqs: [
            {
                id: 'cateringHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cateringOthers'
            },
            {
                id: 'cateringQuestionnaire',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a>#TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_2.png',
            contractTemplates:
                '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_3.png',
            brocureTemplates:
                '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_4.png',
            proposalTemplates:
                '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_5.png',
            questionnarieTemplates:
                '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_6.png'
        },
        emailTemplates:
            '/marketing_site/vertical-lps/Vertical%203%20_%20Caterers/3%20_%20Final%20Deliverables/SEO_catering_rebrand_7.png'
    },
    virtualAssistantPage: {
        ogTags: {
            url: 'https://www.honeybook.com/virtual-assistant-software',
            title: 'Virtual Assistant Software - Making Your Job So Much Easier',
            description:
                'Our virtual assistant software allows you to easily manage invoices, contracts, questionnaires and client communication on the go. Build client trust today'
        },
        hero: {
            image: 'marketing_site/vertical-lps/virtualAssistant.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'virtualOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualGrowth',
                answer: '#TEXT# <a href="https://www.upwork.com/" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualWork',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT_#</a> #TEXT#'
            },
            {
                id: 'virtualMobile',
                answer: `#TEXT# <a href="https://apps.apple.com/us/app/honeybook/id${process.env.GATSBY_honeybookAppId}">#LINK_TEXT#</a> #TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US">#LINK_TEXT#</a> #TEXT#`
            },
            {
                id: 'virtualTools'
            },
            {
                id: 'virtualContractTemplates',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualProposalTemplates',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualQuestionnaire'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%205%20-%20General/Copy_of_Group_48.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    realEstatePage: {
        ogTags: {
            url: 'https://www.honeybook.com/real-estate-crm-software',
            title: 'Real Estate CRM Software: All-in-One',
            description:
                'Improve your client management with our real estate CRM software. Organize client communication, contracts, and payments all in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/real_estate_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'realEstateOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'realEstateTypes',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-send-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'realEstateTemplates',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%205%20-%20General/Copy_of_Group_48.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    socialMediaPage: {
        ogTags: {
            url: 'https://www.honeybook.com/social-media-crm',
            title: 'CRM for Social Media Consultants to Run Your Business',
            description:
                'A streamlined CRM for social media consultants helps you run your entire business on the go. Stay productive and easily track payments in real-time.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/social_media_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'socialMediaTypes',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'socialMediaTemplates',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    homeAccountingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-home-accountants',
            title: 'Client Management Software for Accountants',
            description: `Explore HoneyBook’s client management software for accountants. Manage proposals, invoices, contracts, scheduling and client communication all in one place.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_1_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_2.png',
            'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_3.png'
        ],
        faqs: [
            {
                id: 'accountingTypes',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'accountingPortal',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2814167-your-clients-honeybook-experience" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'accountingBenefit',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'accountingQuickbooks',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209038-quickbooks-integration-overview-faq" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'accountingTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'accountingTemplates',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_4.png',
            contractTemplates:
                'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_5.png',
            brocureTemplates:
                'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_6.png',
            proposalTemplates:
                'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_7.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_8.png'
        },
        emailTemplates:
            'marketing_site/vertical-lps/Vertical%202%20_%20Accountants/3%20_%20Final%20Deliverables/SEO_Accountants_9.png'
    },
    interiorDesignPage: {
        ogTags: {
            url: 'https://www.honeybook.com/interior-design-business-software',
            title: 'Interior Design Management Software',
            description:
                ' Grow your interior design business with HoneyBook’s interior design management software. Keep all client communication, contracts, payments, and invoices in one organized place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/interiorDesign.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'interiorDesignOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'interiorDesignContracts',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'interiorDesignQuestionnaires'
            },
            {
                id: 'interiorDesignTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/desing_invoice_final.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/design_contract_final.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/Group_77.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%204%20-%20Design/design_final_propsal.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    digitalMarketingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/tools-for-digital-marketers',
            title: 'Digital Marketing Invoice Template',
            description: `Client management software for digital marketers including digital marketing invoice templates. Completely customizable.`
        },
        hero: {
            image: 'marketing_site/vertical-lps/digitalMarketer.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'ditigalMarketingOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'digitalMarketingTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'digitalMarketingTemplates'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    copywritingPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-copywriters',
            title: 'Copywriting Software CRM (All-In-One)',
            description:
                'Grow your copywriting business. Onboard clients, streamline all your client communication, and manage payments with copywriting software for copywriters.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/tutor_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'copywritersOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'copywritersTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'copywritersContractTemplates',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'copywritersProposalTemplates',
                answer: '#TEXT# <a href="/proposal-software">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'copywritersTemplates',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%201%20-%20Consultant/consulting_contract_final.png',
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    lifeCoachPage: {
        ogTags: {
            url: 'https://www.honeybook.com/life-coach-software',
            title: 'Life Coach Software: All-in-One CRM',
            description:
                'Improve your client management with our CRM life coach software. Organize client communication, contracts, and payments all in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/lifeCoach.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/freelance-lp/module_3_freelancer_workspace.png',
            'marketing_site/freelance-lp/module_4__freelancer_mobile.png'
        ],
        faqs: [
            {
                id: 'lifeCoachOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lifeCoachTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lifeCoachForm',
                answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209082-customizing-the-honeybook-contact-form" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'lifeCoachTemplates',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateBlocks: [
            'invoiceTemplates',
            'contractTemplates',
            'brocureTemplates',
            'proposalTemplates',
            'questionnarieTemplates'
        ],
        templateFeatureImages: {
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/coaching_contract_final.png',
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    cleaningPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-cleaning-business',
            title: 'Cleaning Business Software - Clean Up Your Clientflow',
            description:
                "Get organized quickly and easily. Keep client communication, contracts, scheduling, and payments all in one place with HoneyBook's cleaning business software"
        },
        hero: {
            image: 'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_1_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/freelance-lp/module2_freelance_workspace.png',
            'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_2.png',
            'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_3.png'
        ],
        faqs: [
            {
                id: 'cleaningOverview'
            },
            {
                id: 'cleaningContractTemplates',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleaningInvoiceTemplates',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleaningProposalTemplates',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_4.png',
            contractTemplates:
                'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_clearing_5-1.png',
            brocureTemplates:
                'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_6.png',
            proposalTemplates:
                'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_7_2x.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_8.png'
        },
        emailTemplates:
            'marketing_site/vertical-lps/Vertical%201%20_%20Cleaners/3%20_%20Final%20Deliverables/SEO_cleaning_9.png'
    },
    floristsPage: {
        ogTags: {
            url: 'https://www.honeybook.com/software-for-florists',
            title: 'Florist Software for Client Management',
            description:
                'Use florist software to create and send custom florist proposals and invoices. Keep client communication, contracts, and payments in one place.'
        },
        hero: {
            image: 'marketing_site/vertical-lps/florist.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/florist_page/florist_pipeline.png',
            'marketing_site/florist_page/florist_workspace.png',
            'marketing_site/florist_page/florist_mobile.png'
        ],
        faqs: [
            {
                id: 'floristWhatIsHB',
                answer: '#TEXT <a href="/freelance-consulting-contract-template">#TEXT#</a> <a href="https://www.honeybook.com/online-payment-software">#TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ&t=1s" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'floristCanICustomize'
            },
            {
                id: 'floristHBSupport',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'floristWalkthroughs',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a>#TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/invoice_all.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/brochure_all.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/proposal_all.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    },
    healthCoachesPage: {
        ogTags: {
            url: 'https://www.honeybook.com/health-coach-software',
            title: 'Software for Health Coaches',
            description:
                'Software for health coaches to grow your coaching business. Manage client communication, contracts, and payments. Get started with templates made for you.'
        },
        hero: {
            image: 'marketing_site/health-coach-lp/health_wellness_coach_hero.png'
        },
        pictureAndListBlockImages: [
            'marketing_site/health-coach-lp/module2_wellness_pipeline.png',
            'marketing_site/health-coach-lp/module3_health_wellness_workspace.png',
            'marketing_site/health-coach-lp/module4_health_wellness_mobile.png'
        ],
        faqs: [
            {
                id: 'healthCoachOverview',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachCRMHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachTools',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachTemplates',
                answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209099-using-templates" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachSession',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=teD20iJHtbk" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachOthers',
                answer: '#TEXT# <a href="/features" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/reviews" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachMasseuse'
            },
            {
                id: 'healthCoachDoula'
            },
            {
                id: 'healthCoachTherapist'
            },
            {
                id: 'healthCoachPTHelp',
                answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'healthCoachPTOthers'
            },
            {
                id: 'busy',
                answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a>#TEXT#'
            },
            {
                id: 'tutorials',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ],
        includeTemplateBlocks: true,
        templateFeatureImages: {
            invoiceTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_75_Copy_1.png',
            contractTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/coaching_contract_final.png',
            brocureTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_73_new.png',
            proposalTemplates:
                'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_77_Copy_4.png',
            questionnarieTemplates:
                'marketing_site/vertical-lps/template-images/group-42_3x.png'
        }
    }
};

interface Props {
    pageContext: {
        contentKey: string;
    };
}

function VerticalTemplate({ pageContext: { contentKey } }: Props): JSX.Element {
    const { i18n, t } = useTranslation();

    const { featurePropImages, templateBlocks } = data.generic;
    const {
        ogTags,
        hero,
        pictureAndListBlockImages,
        faqs,
        includeTemplateBlocks,
        templateFeatureImages,
        templateBlockLinks
    } = data[contentKey];

    const imageTextTransKey = i18n.exists(`${contentKey}.imageTextElements`)
        ? contentKey
        : 'verticalPage';

    return (
        <Layout ogTags={ogTags}>
            <div className={`vertical vertical-lp vertical-lp--${contentKey}`}>
                <WhiteHero
                    image={hero.image}
                    imageAltTag={
                        i18n.exists(`${contentKey}.heroAltTag`)
                            ? t(`${contentKey}.heroAltTag`)
                            : t(`${contentKey}.heroTitle`)
                    }
                    mainTitle={t(`${contentKey}.heroTitle`)}
                    description={
                        i18n.exists(`${contentKey}.subtitle`)
                            ? t(`${contentKey}.subtitle`)
                            : t('verticalPage.hero.subtitle')
                    }
                    showRegistration
                    width={800}
                />
                <FeatureBlockSection
                    customClass="vertical-lp__image-text-block payments-block image-text-elements-block"
                    lightBackground
                >
                    <FeatureBlockContent>
                        <div className="image-text-elements-block__container">
                            {featurePropImages.map((image, i) => (
                                <ImageTextElement
                                    key={t(
                                        `${imageTextTransKey}.c.imgText${
                                            i + 1
                                        }.title`
                                    ).toString()}
                                    imagePath={image}
                                    title={
                                        <Trans
                                            i18nKey={`${imageTextTransKey}.imageTextElements.imgText${
                                                i + 1
                                            }.title`}
                                            components={TRANSLATION_COMPONENTS}
                                        />
                                    }
                                    description={
                                        <Trans
                                            i18nKey={`${contentKey}.featurePropositions.paragraph${
                                                i + 1
                                            }`}
                                            components={TRANSLATION_COMPONENTS}
                                        />
                                    }
                                    mediaFlags={{
                                        forceImgTag: true,
                                        width: 50
                                    }}
                                />
                            ))}
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <PictureAndListBlock
                    title={`${contentKey}.pipelineModule.title`}
                    listName={`${contentKey}.pipelineModule.list`}
                    image={pictureAndListBlockImages[0]}
                    imageAltTag={
                        i18n.exists(`${contentKey}.pipelineModule.imageAltTag`)
                            ? t(`${contentKey}.pipelineModule.imageAltTag`)
                            : t(`${contentKey}.pipelineModule.title`)
                    }
                />

                <PictureAndListBlock
                    title={`${contentKey}.workspaceModule.title`}
                    listName={`${contentKey}.workspaceModule.list`}
                    image={pictureAndListBlockImages[1]}
                    imageAltTag={
                        i18n.exists(`${contentKey}.workspaceModule.imageAltTag`)
                            ? t(`${contentKey}.workspaceModule.imageAltTag`)
                            : t(`${contentKey}.workspaceModule.title`)
                    }
                    imageWidth={550}
                />
                <PictureAndListBlock
                    title={`${contentKey}.mobileModule.title`}
                    listName={`${contentKey}.mobileModule.list`}
                    image={pictureAndListBlockImages[2]}
                    imageAltTag={
                        i18n.exists(`${contentKey}.mobileModule.imageAltTag`)
                            ? t(`${contentKey}.mobileModule.imageAltTag`)
                            : t(`${contentKey}.mobileModule.title`)
                    }
                    imageWidth={415}
                />

                <ThinkOfSwitchingBlock
                    title={`${contentKey}.thinkingOfSwitchingTitle`}
                    preTitle="verticalPage.CTAStrip.preTitle"
                />

                <FeatureGridBlock
                    title={t(`${contentKey}.featureGridHeadline`)}
                    customData={
                        i18n.exists(`${contentKey}.featureGrid`)
                            ? getCustomFeatureGridData(contentKey)
                            : null
                    }
                    lightBackground
                />

                {includeTemplateBlocks && (
                    <>
                        <VerticalTemplatesIntro contentKey={contentKey} />

                        {templateBlocks.map(templateBlock => (
                            <VerticalTemplatesBlock
                                key={'vertical-' + templateBlock}
                                contentKey={contentKey}
                                templateBlock={templateBlock}
                                templateFeatureImages={templateFeatureImages}
                                templateBlockLinks={templateBlockLinks}
                            />
                        ))}

                        <VerticalEmailTemplatesBlock
                            contentKey={contentKey}
                            image={data[contentKey]?.emailTemplates}
                            imageAlt={t(
                                `${contentKey}.emailTemplates.imageAltText`,
                                { defaultValue: null }
                            )}
                        />
                    </>
                )}

                <FAQBlock title={t(`${contentKey}.faqTitle`)} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </div>
        </Layout>
    );
}

VerticalTemplate.propTypes = {
    contentKey: PropTypes.string
};

export default VerticalTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "verticals", "verticalPaid", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
