import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../components/FeatureBlockElements';
import HBLoader from '../components/honeybook-ui/HBLoader/HBLoader';
import FeedbackHeader from '../components/Feedback/FeedbackHeader/FeedbackHeader';
import FeedbackForm from '../components/Feedback/FeedbackForm/FeedbackForm';
import FeedbackSuccess from '../components/Feedback/FeedbackSuccess/FeedbackSuccess';
import UtilsService from '../Services/UtilsService';
import ApiService from '../Services/ApiService';
import { trackError } from '../utils/error.utils';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';

import '../styles/feedback.scss';

const ogTags = {
    url: 'https://www.honeybook.com/feedback',
    title: 'HoneyBook | Clientflow Management for Independent Businesses',
    description:
        'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    noindex: true
};

const Feedback = () => {
    const [memberName, setMemberName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyLogoUrl, setCompanyLogoUrl] = useState('');
    const [rate, setRate] = useState(0);
    const [feedbackText, setFeedbackText] = useState('');
    const [customPlaceholder, setCustomPlaceholder] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const feedbackId = UtilsService.getParameterByName('client_feedback_id');
    const initialRate = UtilsService.getParameterByName('rate');

    useEffect(() => {
        if (!feedbackId) {
            AnalyticsService.track(AnalyticsEvents.feedback_form_id_missing);
            trackError('missing client feedback id');
            window.location.href = '/';
            return;
        }

        ApiService.getFeedbackData(feedbackId).then(res => {
            if (res) {
                initFeedback(res);
                AnalyticsService.track(AnalyticsEvents.feedback_page_loaded, {
                    action: 'loaded',
                    feedbackId: feedbackId,
                    isSubmitted: res?.feedback_submitted
                });
            } else {
                AnalyticsService.track(
                    AnalyticsEvents.feedback_form_loading_failed
                );
                trackError('error feedback page load', {
                    client_feedback_id: feedbackId
                });

                window.location.href = '/';
            }
        });
    }, [
        UtilsService.getParameterByName,
        trackError,
        AnalyticsService.track,
        ApiService.getFeedbackData
    ]);

    const initFeedback = ({
        company_name,
        member_name,
        company_logo_url,
        feedback_submitted,
        feedback_request_prompt
    }) => {
        setIsSubmitted(feedback_submitted);
        setCompanyName(company_name);
        setMemberName(member_name);
        setCompanyLogoUrl(company_logo_url);
        setCustomPlaceholder(feedback_request_prompt);
        if (initialRate) {
            setRate(parseInt(initialRate));
        }
        setIsLoading(false);
    };

    const handleSubmit = () => {
        AnalyticsService.trackClick(AnalyticsEvents.feedback_form_cta_click, {
            feedbackId,
            rating_score: rate,
            rating_comment: feedbackText
        });

        if (!rate) {
            setIsError(true);
            AnalyticsService.trackClick(
                AnalyticsEvents.feedback_form_missing_review_rating,
                {
                    feedbackId
                }
            );
            trackError('error missing review rating', {
                client_feedback_id: feedbackId
            });
        } else if (feedbackId) {
            ApiService.sendFeedbackData(feedbackId, rate, feedbackText);
            setIsSubmitted(true);

            AnalyticsService.track(AnalyticsEvents.feedback_form_submitted, {
                feedbackId,
                rating_score: rate,
                rating_comment: feedbackText
            });
        }
    };

    const onSelectRate = rate => {
        if (isError) {
            setIsError(false);
        }
        setRate(rate);
    };

    if (isLoading) return <HBLoader />;

    return (
        <Layout customClass="feedback" ogTags={ogTags} noFooter noNav noCta>
            <FeatureBlockSection customClass="feedback__container">
                <FeatureBlockContent customClass="feedback__content">
                    <FeedbackHeader
                        isSubmitted={isSubmitted}
                        companyLogoUrl={companyLogoUrl}
                        companyName={companyName}
                    />

                    {!isSubmitted ? (
                        <FeedbackForm
                            memberName={memberName}
                            rate={rate}
                            isError={isError}
                            customPlaceholder={customPlaceholder}
                            setRate={onSelectRate}
                            handleSubmit={handleSubmit}
                            setFeedbackText={setFeedbackText}
                            setIsError={setIsError}
                        />
                    ) : (
                        <FeedbackSuccess />
                    )}
                </FeatureBlockContent>
            </FeatureBlockSection>
        </Layout>
    );
};

export default Feedback;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "feedback"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
