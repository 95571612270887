import { graphql } from 'gatsby';
import '../components/PublicTemplateIframe/template-preview.scss';
import Layout from '../components/Layout/Layout';
import { FullScreenPublicTemplate } from '../components/PublicTemplateIframe/FullScreenPublicTemplate';
import UtilsService from '../Services/UtilsService';

const ogTags = {
    url: 'https://www.honeybook.com/template-preview',
    title: 'Check out my HoneyBook template!',
    description: ``,
    noIndex: true
};

const TemplatePreviewPage = ({ templateId }) => {
    templateId = templateId || UtilsService.getParameterByName('templateId');

    return (
        <Layout ogTags={ogTags} noFooter noPromo noNav>
            <div className="template-preview">
                <FullScreenPublicTemplate templateId={templateId} />
            </div>
        </Layout>
    );
};

export default TemplatePreviewPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "translations", "onlineInvoices", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
