import { useTranslation } from 'react-i18next';
import HBText from '../../honeybook-ui/HBText/HBText';
import StarsIcon from '../../../svg/stars.inline.svg';
import RegistrationFormAsText from '../../RegistrationFormAsText/RegistrationFormAsText';

import './feedback-success.scss';

const FeedbackSuccess = () => {
    const { t } = useTranslation(undefined, {
        keyPrefix: 'feedback_success'
    });

    return (
        <div className="feedback-success">
            <div className="feedback-success__text-container">
                <HBText
                    customClass="feedback-success__text-success"
                    type="body-l"
                    tag="p"
                >
                    {t('success') as string}
                </HBText>
                <HBText
                    customClass="feedback-success__text-thanks"
                    type="body-s"
                    tag="p"
                >
                    {t('thanks') as string}
                </HBText>
            </div>
            <div className="feedback-success__footer">
                <StarsIcon className="feedback-success__stars" />
                <HBText
                    customClass="feedback-success__footer-text"
                    type="body-s"
                    tag="p"
                >
                    {t('footer_text') as string}
                </HBText>
                <RegistrationFormAsText
                    customClass="feedback-success__footer-cta"
                    source="client-feedback"
                    size="medium"
                    color="dark"
                />
            </div>
        </div>
    );
};

export default FeedbackSuccess;
