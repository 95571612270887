import { PublicTemplateIframe } from './PublicTemplateIframe';
import type { TemplateIdProps } from './type';

export const FullScreenPublicTemplate = ({ templateId }: TemplateIdProps) => {
    return (
        <div className="template-preview__full-screen-preview">
            <PublicTemplateIframe templateId={templateId} />
        </div>
    );
};
