"use strict";
exports.__esModule = true;
exports.pagesSet = void 0;
var HomePage = './src/templates/home.tsx';
var NotFoundTemplate = './src/templates/404.tsx';
var ActivationTemplate = './src/templates/activation.tsx';
var FeedbackTemplate = './src/templates/feedback.tsx';
var Unsupported = './src/templates/unsupported.tsx';
var AdminLoginAsTemplate = './src/templates/admin-login-as.tsx';
var MalkutLoginAsTemplate = './src/templates/malkut-login-as.tsx';
var AboutUsTemplate = './src/templates/about.tsx';
var ContactUsTemplate = './src/templates/contact.tsx';
var AllInOneBusinessPlatformTemplate = './src/templates/all-in-one-business-platform.tsx';
var BusinessPersonalityTemplate = './src/templates/business-personality.tsx';
var BusynessTemplate = './src/templates/busyness.tsx';
var BusinessPersonalityToolResultTemplate = './src/templates/business-personality-tool-result.tsx';
var BusinessPersonalityTestResultTemplate = './src/templates/business-personality-test-result.tsx';
var CanadaTemplate = './src/templates/canada.tsx';
var ClientPortalSoftwareTemplate = './src/templates/client-portal-software.tsx';
var CoachingPhotographyTemplate = './src/templates/coaching-photography.tsx';
var ComparisonTemplate = './src/templates/comparison.jsx';
var InvoiceHubTemplate = './src/templates/invoice-hub.tsx';
var TaxCalculatorTemplate = './src/templates/tax-calculator-page.tsx';
var InvoiceGeneratorTemplatePage = './src/templates/invoice-template.tsx';
var EmailSignatureGenerator = './src/templates/email-signature-generator.tsx';
var EmailSignatureViewer = './src/templates/email-signature-viewer.tsx';
var CareersTemplate = './src/templates/careers.tsx';
var InvitesTemplate = './src/templates/invites-page.jsx';
var DjBookingSoftwareTemplate = './src/templates/dj-booking-software.jsx';
var EducatorsTemplate = './src/templates/educators.jsx';
var FeaturesTemplate = './src/templates/features.tsx';
var ReviewsTemplate = './src/templates/reviews.tsx';
var FAQsTemplate = './src/templates/faqs-page.jsx';
var FreeAccountMigrationTemplate = './src/templates/free-account-migration.tsx';
var FreeContractTemplatesTemplate = './src/templates/free-contract-templates.tsx';
var HealthCoachSoftwareTemplate = './src/templates/health-coach-software.jsx';
var GetOnlineContractTemplate = './src/templates/get-online-contract.tsx';
var GetOnlineInvoicesTemplate = './src/templates/get-online-invoices.tsx';
var OnlineInvoicesTemplate = './src/templates/online-invoices-new.tsx';
var GetInvoicesTemplate = './src/templates/get-invoice-templates.tsx';
var OnlinePaymentSoftwareTemplate = './src/templates/online-payment-software.jsx';
var PaymentRemindersTemplate = './src/templates/payment-reminders.jsx';
var PressTemplate = './src/templates/press.jsx';
var PricingTemplate = './src/templates/pricing.tsx';
var ProductEducationTemplate = './src/templates/product-education.jsx';
var ProposalSoftwareTemplate = './src/templates/proposal-software.jsx';
var ResourcesTemplate = './src/templates/resources.jsx';
var SitemapTemplate = './src/templates/sitemap.jsx';
var TermsOfServiceTemplate = './src/templates/terms-of-service.jsx';
var WorkflowsTemplate = './src/templates/workflows.jsx';
var MeetingSchedulerTemplate = './src/templates/meeting-scheduler.jsx';
var OnlineContractTemplate = './src/templates/online-contract.tsx';
var CrmTemplate = './src/templates/crm.tsx';
var CrmSEOTemplate = './src/templates/crm-seo.tsx';
var ReferralsTemplate = './src/templates/referrals.tsx';
var VerticalTemplate = './src/templates/vertical.tsx';
var InvoiceGeneratorTemplate = './src/templates/invoice-generator.tsx';
var ContractTemplateTemplate = './src/templates/contract-template.tsx';
var PaidMarketingPage = './src/templates/paid-marketing.tsx';
var TemplatesPage = './src/templates/templates-rebrand.tsx';
var ContractsPerIndustry = './src/templates/contracts-per-industry.tsx';
var VerticalPaid = './src/templates/vertical-paid.tsx';
var CrmForPhotographersTemplate = './src/templates/crm-for-photographers.jsx';
var EventPlannersTemplate = './src/templates/event-planners.jsx';
var VenueManagementSoftwareTemplate = './src/templates/venue-management-software.jsx';
var MissionStatementTemplate = './src/templates/mission-statement.tsx';
var EsignTemplate = './src/templates/esign.tsx';
var OnlineDocumentSigningTemplate = './src/templates/esign-seo.tsx';
var OnlineSignatureGeneratorTemplate = './src/templates/esign-online.tsx';
var TemplatePreview = './src/templates/template-preview.tsx';
var AdobePage = './src/templates/adobe.tsx';
var pagesList = [
    {
        path: '/',
        component: HomePage,
        context: {}
    },
    {
        path: '/adobe',
        component: AdobePage,
        context: {}
    },
    {
        path: '/about',
        component: AboutUsTemplate,
        context: {}
    },
    {
        path: '/meeting-scheduler',
        component: MeetingSchedulerTemplate,
        context: {}
    },
    {
        path: '/contact-us',
        component: ContactUsTemplate,
        context: {}
    },
    {
        path: '/invites',
        component: InvitesTemplate,
        context: {}
    },
    {
        path: '/canada',
        component: CanadaTemplate,
        context: {}
    },
    {
        path: '/all-in-one-business-platform',
        component: AllInOneBusinessPlatformTemplate,
        context: {}
    },
    {
        path: '/business-personality-test',
        component: BusinessPersonalityTemplate,
        context: {}
    },
    {
        path: '/busyness',
        component: BusynessTemplate,
        context: {}
    },
    {
        path: '/client-portal-software',
        component: ClientPortalSoftwareTemplate,
        context: {}
    },
    {
        path: '/coaching/photography/joy-michelle',
        component: CoachingPhotographyTemplate,
        context: {}
    },
    {
        path: '/honeybook-vs-dubsado',
        component: ComparisonTemplate,
        context: {
            contentKey: 'dubsado'
        }
    },
    {
        path: '/honeybook-vs-freshbooks',
        component: ComparisonTemplate,
        context: {
            contentKey: 'freshbooks'
        }
    },
    {
        path: '/honeybook-vs-bonsai',
        component: ComparisonTemplate,
        context: {
            contentKey: 'bonsai'
        }
    },
    {
        path: '/honeybook-vs-17hats',
        component: ComparisonTemplate,
        context: {
            contentKey: 'seventeenHats'
        }
    },
    {
        path: '/honeybook-vs-pipedrive',
        component: ComparisonTemplate,
        context: {
            contentKey: 'pipedrive'
        }
    },
    {
        path: '/honeybook-vs-monday',
        component: ComparisonTemplate,
        context: {
            contentKey: 'mondayDotCom'
        }
    },
    {
        path: '/honeybook-vs-vcita',
        component: ComparisonTemplate,
        context: {
            contentKey: 'vcita'
        }
    },
    {
        path: '/careers',
        component: CareersTemplate,
        context: {}
    },
    {
        path: '/crm-for-consultants',
        component: VerticalTemplate,
        context: {
            contentKey: 'businessConsultantPage'
        }
    },
    {
        path: '/faqs',
        component: FAQsTemplate,
        context: {}
    },
    {
        path: '/crm-for-freelancers',
        component: VerticalTemplate,
        context: {
            contentKey: 'freelancerPage'
        }
    },
    {
        path: '/crm-for-photographers',
        component: CrmForPhotographersTemplate,
        context: {}
    },
    {
        path: '/contracts-for-cinematographers',
        component: ContractsPerIndustry,
        context: {
            contentKey: 'cinematographers'
        }
    },
    {
        path: '/contracts-for-videographers',
        component: ContractsPerIndustry,
        context: {
            contentKey: 'videographers'
        }
    },
    {
        path: '/photography-contract-templates',
        component: ContractTemplateTemplate,
        context: {
            contentKey: 'photography'
        }
    },
    {
        path: '/designer-contract-template',
        component: ContractsPerIndustry,
        context: {
            contentKey: 'designer'
        }
    },
    {
        path: '/contracts-for-event-and-wedding-planners',
        component: ContractsPerIndustry,
        context: {
            contentKey: 'weddingPlanners'
        }
    },
    {
        path: '/dj-booking-software',
        component: DjBookingSoftwareTemplate,
        context: {}
    },
    {
        path: '/software-for-florists',
        component: VerticalTemplate,
        context: {
            contentKey: 'floristsPage'
        }
    },
    {
        path: '/all-in-one-software-for-cinematography',
        component: VerticalTemplate,
        context: {
            contentKey: 'cinematographersPage'
        }
    },
    {
        path: '/all-in-one-software-for-wedding-photographers',
        component: VerticalTemplate,
        context: {
            contentKey: 'weddingPhotographersPage'
        }
    },
    {
        path: '/catering-management-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'cateringPage'
        }
    },
    {
        path: '/doula-business-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'doulaPage'
        }
    },
    {
        path: '/software-for-event-planners',
        component: EventPlannersTemplate,
        context: {}
    },
    {
        path: '/educators',
        component: EducatorsTemplate,
        context: {}
    },
    {
        path: '/features',
        component: FeaturesTemplate,
        context: {}
    },
    {
        path: '/reviews',
        component: ReviewsTemplate,
        context: {}
    },
    {
        path: '/free-account-migration',
        component: FreeAccountMigrationTemplate,
        context: {}
    },
    {
        path: '/free-contract-templates',
        component: FreeContractTemplatesTemplate,
        context: {}
    },
    {
        path: '/health-coach-software',
        component: HealthCoachSoftwareTemplate,
        context: {}
    },
    {
        path: '/online-contract',
        component: OnlineContractTemplate,
        context: {}
    },
    {
        path: '/online-invoices',
        component: OnlineInvoicesTemplate,
        context: {}
    },
    {
        path: '/get-online-invoices',
        component: GetOnlineInvoicesTemplate,
        context: {}
    },
    {
        path: '/get-online-contract',
        component: GetOnlineContractTemplate,
        context: {}
    },
    {
        path: '/get-invoice-templates',
        component: GetInvoicesTemplate,
        context: {}
    },
    {
        path: '/get-started-crm',
        component: CrmTemplate,
        context: {}
    },
    {
        path: '/get-started-easy-crm',
        component: CrmTemplate,
        context: {
            contentKey: 'easyCrm'
        }
    },
    {
        path: '/get-started-free-crm',
        component: CrmTemplate,
        context: {
            contentKey: 'freeCrm'
        }
    },
    {
        path: '/online-payment-software',
        component: OnlinePaymentSoftwareTemplate,
        context: {}
    },
    {
        path: '/payment-reminders',
        component: PaymentRemindersTemplate,
        context: {}
    },
    {
        path: '/press',
        component: PressTemplate,
        context: {}
    },
    {
        path: '/pricing',
        component: PricingTemplate,
        context: {}
    },
    {
        path: '/product-education',
        component: ProductEducationTemplate,
        context: {}
    },
    {
        path: '/proposal-software',
        component: ProposalSoftwareTemplate,
        context: {}
    },
    {
        path: '/referrals',
        component: ReferralsTemplate,
        context: {}
    },
    {
        path: '/resources',
        component: ResourcesTemplate,
        context: {}
    },
    /* business-personality-tool-result */
    {
        path: '/business-personality-tool-result',
        component: BusinessPersonalityToolResultTemplate,
        context: {}
    },
    {
        path: '/tools/self-employment-tax-calculator',
        component: TaxCalculatorTemplate,
        context: {}
    },
    {
        path: '/tools/self-employment-tax-calculator-california',
        component: TaxCalculatorTemplate,
        context: { USState: 'CA' }
    },
    {
        path: '/tools/self-employment-tax-calculator-texas',
        component: TaxCalculatorTemplate,
        context: { USState: 'TX' }
    },
    {
        path: '/tools/self-employment-tax-calculator-florida',
        component: TaxCalculatorTemplate,
        context: { USState: 'FL' }
    },
    {
        path: '/tools/self-employment-tax-calculator-new-york',
        component: TaxCalculatorTemplate,
        context: { USState: 'NY' }
    },
    {
        path: '/tools/self-employment-tax-calculator-pennsylvania',
        component: TaxCalculatorTemplate,
        context: { USState: 'PA' }
    },
    {
        path: '/tools/self-employment-tax-calculator-illinois',
        component: TaxCalculatorTemplate,
        context: { USState: 'IL' }
    },
    {
        path: '/tools/self-employment-tax-calculator-ohio',
        component: TaxCalculatorTemplate,
        context: { USState: 'OH' }
    },
    {
        path: '/tools/self-employment-tax-calculator-georgia',
        component: TaxCalculatorTemplate,
        context: { USState: 'GA' }
    },
    {
        path: '/tools/self-employment-tax-calculator-north-carolina',
        component: TaxCalculatorTemplate,
        context: { USState: 'NC' }
    },
    {
        path: '/tools/self-employment-tax-calculator-michigan',
        component: TaxCalculatorTemplate,
        context: { USState: 'MI' }
    },
    {
        path: '/business-personality-test-result/visionary-rising-star',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'visionaryRisingStar'
        }
    },
    {
        path: '/business-personality-test-result/visionary-wave-maker',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'visionaryWaveMaker'
        }
    },
    {
        path: '/business-personality-test-result/visionary-driving-force',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'visionaryDrivingForce'
        }
    },
    {
        path: '/business-personality-test-result/visionary-empire-builder',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'visionaryEmpireBuilder'
        }
    },
    {
        path: '/business-personality-test-result/creative-rising-star',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'creativeRisingStar'
        }
    },
    {
        path: '/business-personality-test-result/creative-wave-maker',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'creativeWaveMaker'
        }
    },
    {
        path: '/business-personality-test-result/creative-driving-force',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'creativeDrivingForce'
        }
    },
    {
        path: '/business-personality-test-result/creative-empire-builder',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'creativeEmpireBuilder'
        }
    },
    {
        path: '/business-personality-test-result/actualizer-rising-star',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'actualizerRisingStar'
        }
    },
    {
        path: '/business-personality-test-result/actualizer-wave-maker',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'actualizerWaveMaker'
        }
    },
    {
        path: '/business-personality-test-result/actualizer-driving-force',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'actualizerDrivingForce'
        }
    },
    {
        path: '/business-personality-test-result/actualizer-empire-builder',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'actualizerEmpireBuilder'
        }
    },
    {
        path: '/business-personality-test-result/connector-rising-star',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'connectorRisingStar'
        }
    },
    {
        path: '/business-personality-test-result/connector-wave-maker',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'connectorWaveMaker'
        }
    },
    {
        path: '/business-personality-test-result/connector-driving-force',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'connectorDrivingForce'
        }
    },
    {
        path: '/business-personality-test-result/connector-empire-builder',
        component: BusinessPersonalityTestResultTemplate,
        context: {
            resultKey: 'connectorEmpireBuilder'
        }
    },
    {
        path: '/sitemap',
        component: SitemapTemplate,
        context: {}
    },
    {
        path: '/venue-management-software',
        component: VenueManagementSoftwareTemplate,
        context: {}
    },
    {
        path: '/invoice-template',
        component: InvoiceGeneratorTemplatePage,
        context: {}
    },
    {
        path: '/email-signature',
        component: EmailSignatureGenerator,
        context: {}
    },
    {
        path: '/email-signature-viewer',
        component: EmailSignatureViewer,
        context: {},
        excludeFromSitemap: true
    },
    {
        path: '/get-document-signing',
        component: EsignTemplate,
        context: {}
    },
    {
        path: '/online-document-signing',
        component: OnlineDocumentSigningTemplate,
        context: {}
    },
    {
        path: '/online-signature-generator',
        component: OnlineSignatureGeneratorTemplate,
        context: {}
    },
    /* Vertical */
    {
        path: '/marketing-consultant-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'marketingPage'
        }
    },
    {
        path: '/software-for-online-coaching',
        component: VerticalTemplate,
        context: {
            contentKey: 'onlineCoachingPage'
        }
    },
    {
        path: '/software-for-band-managers',
        component: VerticalTemplate,
        context: {
            contentKey: 'bandManagersPage'
        }
    },
    {
        path: '/software-for-pr-companies',
        component: VerticalTemplate,
        context: {
            contentKey: 'PRPage'
        }
    },
    {
        path: '/software-for-web-designers',
        component: VerticalTemplate,
        context: {
            contentKey: 'webDesignersPage'
        }
    },
    {
        path: '/all-in-one-software-for-event-and-wedding-planners',
        component: VerticalTemplate,
        context: {
            contentKey: 'weddingPlannersPage'
        }
    },
    {
        path: '/all-in-one-software-for-event-rental-companies',
        component: VerticalTemplate,
        context: {
            contentKey: 'eventRentalsPage'
        }
    },
    {
        path: '/all-in-one-software-for-photographers',
        component: VerticalTemplate,
        context: {
            contentKey: 'allInOnePhotographersPage'
        }
    },
    {
        path: '/software-for-visual-artists',
        component: VerticalTemplate,
        context: {
            contentKey: 'visualArtistsPage'
        }
    },
    {
        path: '/software-for-dog-trainers',
        component: VerticalTemplate,
        context: {
            contentKey: 'dogTrainersPage'
        }
    },
    {
        path: '/software-for-agencies',
        component: VerticalTemplate,
        context: {
            contentKey: 'agenciesPage'
        }
    },
    {
        path: '/software-for-franchises',
        component: VerticalTemplate,
        context: {
            contentKey: 'franchisePage'
        }
    },
    {
        path: '/crm-for-designers',
        component: VerticalTemplate,
        context: {
            contentKey: 'designPage'
        }
    },
    {
        path: '/crm-for-graphic-designers',
        component: VerticalTemplate,
        context: {
            contentKey: 'graphicDesignPage'
        }
    },
    {
        path: '/crm-for-landscapers',
        component: VerticalTemplate,
        context: {
            contentKey: 'landscapingPage'
        }
    },
    {
        path: '/contractor-billing-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'contractorPage'
        }
    },
    {
        path: '/tutor-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'educationPage'
        }
    },
    {
        path: '/virtual-assistant-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'virtualAssistantPage'
        }
    },
    {
        path: '/real-estate-crm-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'realEstatePage'
        }
    },
    {
        path: '/social-media-crm',
        component: VerticalTemplate,
        context: {
            contentKey: 'socialMediaPage'
        }
    },
    {
        path: '/software-for-home-accountants',
        component: VerticalTemplate,
        context: {
            contentKey: 'homeAccountingPage'
        }
    },
    {
        path: '/interior-design-business-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'interiorDesignPage'
        }
    },
    {
        path: '/tools-for-digital-marketers',
        component: VerticalTemplate,
        context: {
            contentKey: 'digitalMarketingPage'
        }
    },
    {
        path: '/software-for-copywriters',
        component: VerticalTemplate,
        context: {
            contentKey: 'copywritingPage'
        }
    },
    {
        path: '/life-coach-software',
        component: VerticalTemplate,
        context: {
            contentKey: 'lifeCoachPage'
        }
    },
    {
        path: '/software-for-cleaning-business',
        component: VerticalTemplate,
        context: {
            contentKey: 'cleaningPage'
        }
    },
    {
        path: '/all-in-one-software-for-yoga-studios',
        component: VerticalTemplate,
        context: {
            contentKey: 'yogaPage'
        }
    },
    {
        path: '/all-in-one-software-for-estheticians-and-makeup-artists',
        component: VerticalTemplate,
        context: { contentKey: 'estheticiansPage' }
    },
    {
        path: '/all-in-one-software-for-hair-stylists',
        component: VerticalTemplate,
        context: { contentKey: 'hairStylistsPage' }
    },
    {
        path: '/all-in-one-software-for-photo-booth-rental-companies',
        component: VerticalTemplate,
        context: { contentKey: 'photoBoothsPage' }
    },
    {
        path: '/all-in-one-software-for-yoga-studios',
        component: VerticalTemplate,
        context: { contentKey: 'yogaPage' }
    },
    {
        path: '/all-in-one-software-for-teachers',
        component: VerticalTemplate,
        context: { contentKey: 'teachersPage' }
    },
    {
        path: '/all-in-one-software-for-officiants',
        component: VerticalTemplate,
        context: { contentKey: 'officiantsPage' }
    },
    {
        path: '/all-in-one-software-for-private-practice',
        component: VerticalTemplate,
        context: { contentKey: 'privatePracticePage' }
    },
    {
        path: '/all-in-one-software-for-lawyers',
        component: VerticalTemplate,
        context: { contentKey: 'lawyersPage' }
    },
    {
        path: '/all-in-one-software-for-group-practice',
        component: VerticalTemplate,
        context: { contentKey: 'groupPracticePage' }
    },
    /* End of Vertical */
    {
        path: '/automations',
        component: WorkflowsTemplate,
        context: {}
    },
    {
        path: '/client-management-software',
        component: CrmSEOTemplate,
        context: {}
    },
    /* Terms of Service */
    {
        path: '/terms/terms-of-service',
        component: TermsOfServiceTemplate,
        context: {
            contentKey: 'termsOfService'
        }
    },
    {
        path: '/terms/terms-of-service-original',
        component: TermsOfServiceTemplate,
        excludeFromSitemap: true,
        context: {
            contentKey: 'termsOfServiceOriginal'
        }
    },
    {
        path: '/terms/privacy',
        component: TermsOfServiceTemplate,
        context: {
            contentKey: 'privacy'
        }
    },
    {
        path: '/terms/privacy-original',
        component: TermsOfServiceTemplate,
        excludeFromSitemap: true,
        context: {
            contentKey: 'privacyOriginal'
        }
    },
    /* Invoice Generator */
    {
        path: '/free-invoice-templates',
        component: InvoiceHubTemplate,
        context: {}
    },
    {
        path: '/free-invoice-templates/doula',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'doula'
        }
    },
    {
        path: '/free-invoice-templates/designers',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'design'
        }
    },
    {
        path: '/free-invoice-templates/graphic-design',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'graphicDesign'
        }
    },
    {
        path: '/free-invoice-templates/interior-design',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'interiorDesign'
        }
    },
    {
        path: '/free-invoice-templates/consultants',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'consultants'
        }
    },
    {
        path: '/free-invoice-templates/photographers',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'photographers'
        }
    },
    {
        path: '/free-invoice-templates/coach',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'coach'
        }
    },
    {
        path: '/free-invoice-templates/tutors',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'tutors'
        }
    },
    {
        path: '/free-invoice-templates/cleaners',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'cleaners'
        }
    },
    {
        path: '/free-invoice-templates/virtual-assistant',
        component: InvoiceGeneratorTemplate,
        context: {
            contentKey: 'virtualAssistant'
        }
    },
    {
        path: '/templates',
        component: TemplatesPage,
        context: {}
    },
    {
        path: '/freelance-consulting-contract-template',
        component: ContractTemplateTemplate,
        context: {
            contentKey: 'consultants'
        }
    },
    // Paid marketing landing page
    {
        path: '/getstarted',
        component: PaidMarketingPage,
        context: {}
    },
    {
        path: '/industry-photographers',
        component: VerticalPaid,
        context: {
            contentKey: 'photographers'
        }
    },
    {
        path: '/industry-caterers',
        component: VerticalPaid,
        context: {
            contentKey: 'cateringPage'
        }
    },
    {
        path: '/industry-event-planners',
        component: VerticalPaid,
        context: {
            contentKey: 'eventPlannersPage'
        }
    },
    {
        path: '/industry-venue-managers',
        component: VerticalPaid,
        context: {
            contentKey: 'venuesPage'
        }
    },
    {
        path: '/industry-social-media',
        component: VerticalPaid,
        context: {
            contentKey: 'socialMedia'
        }
    },
    {
        path: '/industry-consultants',
        component: VerticalPaid,
        context: {
            contentKey: 'consultant'
        }
    },
    {
        path: '/industry-cinematographers',
        component: VerticalPaid,
        context: {
            contentKey: 'cinematographers'
        }
    },
    {
        path: '/industry-creative-designer',
        component: VerticalPaid,
        context: {
            contentKey: 'creatives'
        }
    },
    {
        path: '/industry-interior-design',
        component: VerticalPaid,
        context: {
            contentKey: 'interiorDesign'
        }
    },
    {
        path: '/industry-freelancers',
        component: VerticalPaid,
        context: {
            contentKey: 'freelancers'
        }
    },
    {
        path: '/industry-wedding-planners',
        component: VerticalPaid,
        context: {
            contentKey: 'weddingPlanners'
        }
    },
    {
        path: '/industry-videographers',
        component: VerticalPaid,
        context: {
            contentKey: 'videographers'
        }
    },
    {
        path: '/industry-marketing',
        component: VerticalPaid,
        context: {
            contentKey: 'marketing'
        }
    },
    {
        path: '/template_preview',
        component: TemplatePreview,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/template-preview/:templateId',
        component: TemplatePreview,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/industry-florists',
        component: VerticalPaid,
        context: {
            contentKey: 'florists'
        }
    },
    {
        path: '/industry-designers',
        component: VerticalPaid,
        context: {
            contentKey: 'designers'
        }
    },
    // legacy Malkut login as
    {
        path: '/ala-facilitator',
        component: AdminLoginAsTemplate,
        excludeFromSitemap: true,
        context: {}
    },
    // Malkut login as
    {
        path: '/ala-facilitator-v2',
        component: MalkutLoginAsTemplate,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/activation',
        component: ActivationTemplate,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/feedback',
        component: FeedbackTemplate,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/unsupported',
        component: Unsupported,
        excludeFromSitemap: true,
        context: {}
    },
    {
        path: '/mission-statement',
        component: MissionStatementTemplate,
        context: {}
    }
];
// 404 — Always last
pagesList.push({
    path: '/404',
    matchPath: '/*',
    component: NotFoundTemplate,
    excludeFromSitemap: true,
    context: {}
});
pagesList.forEach(function (page) {
    var _a;
    (_a = page.context).sourceFilePath || (_a.sourceFilePath = page.component);
});
exports.pagesSet = new Set(pagesList.map(function (page) { return page.path; }));
exports["default"] = pagesList;
