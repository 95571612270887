import { useTranslation } from 'react-i18next';
import HBButton from '../../honeybook-ui/HBButton/HBButton';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBTextArea from '../../honeybook-ui/HBTextArea/HBTextArea';
import StarsInput from '../../StarsInput/StarsInput';

import './feedback-form.scss';

const FeedbackForm = ({
    memberName,
    rate,
    setRate,
    setFeedbackText,
    setIsError,
    isError,
    customPlaceholder,
    handleSubmit
}) => {
    const { t } = useTranslation(undefined, {
        keyPrefix: 'feedback_form'
    });

    const questionStrong = t('question_strong', { memberName }) as string;
    const question = t('question') as string;
    const defaultPlaceholder = t('placeholder', { memberName }) as string;

    return (
        <div className="feedback-form">
            <div className="feedback-form__question-container">
                <HBText
                    customClass="feedback-form__question-strong"
                    type="body-l"
                    tag="p"
                >
                    {questionStrong}
                </HBText>
                <HBText
                    customClass="feedback-form__question"
                    type="body-m"
                    tag="p"
                >
                    {question}
                </HBText>
            </div>
            <StarsInput
                rate={rate}
                setRate={setRate}
                labels={{ min: t('labels.min'), max: t('labels.max') }}
                isError={isError}
            />
            <HBTextArea
                customClass="feedback-form__textarea"
                placeholder={customPlaceholder || defaultPlaceholder}
                onChange={ev => setFeedbackText(ev.target.value)}
            />
            <HBButton text={t('submit')} onClick={handleSubmit} />
        </div>
    );
};

export default FeedbackForm;
