import {
    CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
    CURRENT_REFERER_STORAGE_KEY
} from '../constants/constants';
import * as queryString from 'query-string';
import CookiesService from './CookiesService';
import * as DOMPurify from 'dompurify';
import StorageService from './StorageService';
import type { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import type { TFeaturesCheckListAnalyticsData } from '../components/Hero/FeatureChecklistHero/constants-and-types';
import DeviceService from './DeviceService';
import { Coupons } from '../constants/promoCodes';

export interface Display {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
}

interface AdvertisingData {
    utm_campaign: string | null;
    utm_content: string | null;
    utm_medium: string | null;
    utm_source: string | null;
    utm_term: string | null;
    code: string | null;
    utm_origin: string | null;
    referral_source: string | null;
    gclid: string | null;
    fbclid: string | null;
    irclickid: string | null;
    placement: string | null;
    promo: string | null;
}
declare global {
    interface Window {
        mixpanel: any;
        FS: any;
        OneTrust: any;
        analytics: {
            identify(
                userId: string | null,
                data: Record<string, any>,
                integrations: Record<string, any>
            ): any;
            track(
                eventName: string,
                data: Record<string, any>,
                integrations: Record<string, any>
            ): any;
            page(name, properties, integrations): any;
            reset(): any;
        };
    }
}

const osData = [
    { os: 'Windows 10', regex: /(Windows 10.0|Windows NT 10.0)/ },
    { os: 'Windows 8.1', regex: /(Windows 8.1|Windows NT 6.3)/ },
    { os: 'Windows 8', regex: /(Windows 8|Windows NT 6.2)/ },
    { os: 'Windows 7', regex: /(Windows 7|Windows NT 6.1)/ },
    { os: 'Windows Vista', regex: /Windows NT 6.0/ },
    { os: 'Windows Server 2003', regex: /Windows NT 5.2/ },
    { os: 'Windows XP', regex: /(Windows NT 5.1|Windows XP)/ },
    { os: 'Windows 2000', regex: /(Windows NT 5.0|Windows 2000)/ },
    { os: 'Windows ME', regex: /(Win 9x 4.90|Windows ME)/ },
    { os: 'Windows 98', regex: /(Windows 98|Win98)/ },
    { os: 'Windows 95', regex: /(Windows 95|Win95|Windows_95)/ },
    {
        os: 'Windows NT 4.0',
        regex: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
    },
    { os: 'Windows CE', regex: /Windows CE/ },
    { os: 'Windows 3.11', regex: /Win16/ },
    { os: 'Android', regex: /Android/ },
    { os: 'Open BSD', regex: /OpenBSD/ },
    { os: 'Sun OS', regex: /SunOS/ },
    { os: 'Chrome OS', regex: /CrOS/ },
    { os: 'Linux', regex: /(Linux|X11(?!.*CrOS))/ },
    { os: 'iOS', regex: /(iPhone|iPad|iPod)/ },
    { os: 'Mac OS X', regex: /Mac OS X/ },
    {
        os: 'Mac OS',
        regex: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
    },
    { os: 'QNX', regex: /QNX/ },
    { os: 'UNIX', regex: /UNIX/ },
    { os: 'BeOS', regex: /BeOS/ },
    { os: 'OS/2', regex: /OS\/2/ },
    {
        os: 'Search Bot',
        regex: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
];
let _advertisingData: AdvertisingData | undefined;

export default class UtilsService {
    static getParameterByName = (name: string): string | null => {
        let searchParam;
        if (typeof window !== 'undefined') {
            searchParam = window.location.search;
        } else {
            searchParam = '';
        }

        let result = queryString.parse(searchParam)[name];

        if (Array.isArray(result)) {
            [result] = result;
        }

        return result;
    };

    static convertObjectToQueryString = (
        rawObject: Record<string, unknown>
    ): string => queryString.stringify(rawObject);

    static getUtmParam = (param: keyof AdvertisingData): string | null => {
        const utmParams = this.getAllUtmParams();
        return utmParams[param];
    };

    static getSessionUtmParams(): AdvertisingData | undefined {
        return _advertisingData;
    }

    static getAllUtmParams(): AdvertisingData | {} {
        let utmParams = _advertisingData;
        if (!utmParams) {
            const utmParamsJSON = CookiesService.getCookie('utm_params');
            if (utmParamsJSON) {
                utmParams = JSON.parse(utmParamsJSON);
            }
        }
        return utmParams || {};
    }

    static storeInitialUtms = (promoCode?: string): void => {
        try {
            const advertisingData = this.getAdvertisingData();
            let hasUtms = false;

            Object.keys(advertisingData).forEach(key => {
                const value = advertisingData[key];

                hasUtms = hasUtms || value;
            });

            if (promoCode && promoCode !== Coupons.nopromo) {
                advertisingData.code = promoCode;
                hasUtms = true;
            }

            if (hasUtms) {
                CookiesService.setCookie(
                    'utm_params',
                    JSON.stringify(advertisingData),
                    1
                );
                _advertisingData = advertisingData;
            }
        } catch (error) {
            /* eslint-disable */
            console.error('unable to store utms ', error);
            /* eslint-enable */
        }
    };
    static reloadOTBanner = (): void => {
        // this is Onetrust code from their documentation, to make the scripts render on every page load
        const otConsentSdk = document.getElementById('onetrust-consent-sdk');
        if (otConsentSdk) {
            otConsentSdk.remove();
        }

        if (window.OneTrust != null) {
            window.OneTrust.Init();

            setTimeout(function () {
                window.OneTrust.LoadBanner();

                const toggleDisplay = document.getElementsByClassName(
                    'ot-sdk-show-settings'
                );

                for (var i = 0; i < toggleDisplay.length; i++) {
                    // @ts-ignore
                    toggleDisplay[i].onclick = function (event) {
                        event.stopImmediatePropagation();
                        window.OneTrust.ToggleInfoDisplay();
                    };
                }
            }, 1000);
        }
    };

    static isCurrentUrlInList = (urlList: string[]): boolean => {
        const pathname = window.location.pathname;
        let returnValue = false;

        // Special logic for homepage
        if (urlList.includes('/')) {
            urlList.splice(urlList.indexOf('/'), 1);
            if (pathname === '/') {
                returnValue = true;
            }
        }

        if (
            urlList.some(
                pathPrefix => pathname && pathname.startsWith(pathPrefix)
            )
        ) {
            returnValue = true;
        }
        return returnValue || UtilsService.isInTemplatePreviewPage();
    };

    static storeCoupon = (promo?: string): void => {
        const coupon = promo || this.getCouponFromQueryParams();
        const isCouponValid = coupon && coupon !== Coupons.nopromo;

        if (isCouponValid && window.localStorage?.jStorage) {
            const jstorage = StorageService.getItem('jStorage');

            jstorage.HB_COUPON = coupon;
            StorageService.setItem({ key: 'jStorage', data: jstorage });
        }
    };

    static getCouponFromQueryParams(): string | null {
        return (
            this.getParameterByName('promo') || this.getParameterByName('code')
        );
    }

    static getCouponFromCookies(): string | null {
        const utmParamsJSON = CookiesService.getCookie('utm_params');
        if (utmParamsJSON) {
            const utmParams = JSON.parse(utmParamsJSON);
            return utmParams.promo || utmParams.code;
        }
        return null;
    }

    static getReferralCodeFromCookies(): string | null {
        const utmParamsJSON = CookiesService.getCookie('utm_params');
        if (utmParamsJSON) {
            const utmParams = JSON.parse(utmParamsJSON);
            return utmParams.code;
        }
        return null;
    }

    static convertDiscountStringToNumber(str: string): number | null {
        if (typeof str === 'string') {
            const match = str.match(/(\d+(?:\.\d+)?)%/);
            const number = match ? parseFloat(match[1]) : null;
            return number;
        }
        return null;
    }

    static isProduction = (): boolean =>
        process.env && process.env.GATSBY_env === 'production';

    static isEmailValid = (email: string): boolean =>
        /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i.test(email);

    static isQA = (): boolean =>
        window.location.host.includes('staging-qa-app.honeybook.com');

    static isStaging = (): boolean =>
        process.env.GATSBY_env === 'staging' && !this.isQA();

    static isDev = (): boolean => process.env.GATSBY_env === 'development';

    static getAdvertisingData = (): AdvertisingData => {
        const utmCampaign = this.getParameterByName('utm_campaign');
        const utmContent = this.getParameterByName('utm_content');
        const utmMedium = this.getParameterByName('utm_medium');
        const utmSource = this.getParameterByName('utm_source');
        const utmTerm = this.getParameterByName('utm_term');
        const utmMbsy = this.getParameterByName('code');
        const utmOrigin = this.getParameterByName('utm_origin');
        const referralSource = this.getParameterByName('referral_source');
        const gclidCode = this.getParameterByName('gclid');
        const fbclidCode = this.getParameterByName('fbclid');
        const irclickid = this.getParameterByName('irclickid');
        const placementCode =
            this.getParameterByName('placement') ||
            this.getParameterByName('utm_placement');
        const promoCode = this.getParameterByName('promo');

        return {
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_medium: utmMedium,
            utm_source: utmSource,
            utm_term: utmTerm,
            code: utmMbsy,
            utm_origin: utmOrigin,
            referral_source: referralSource,
            gclid: gclidCode,
            fbclid: fbclidCode,
            irclickid: irclickid,
            placement: placementCode,
            promo: promoCode
        };
    };

    static getSignupLandingPageFromLocationPath = (): string => {
        const locationPathname = window.location.pathname;
        let signupLandingPage = locationPathname.split('?')[0];

        if (signupLandingPage[0] === '/')
            signupLandingPage = signupLandingPage.substring(1);

        if (signupLandingPage === '') {
            signupLandingPage = 'home';
        }

        return signupLandingPage;
    };

    static sanitizeHtml = (string: string): string => {
        return DOMPurify.sanitize(string);
    };

    static toCamelCase = (string: string): string => {
        const returnString = string
            .toLowerCase()
            .replace(/(^.)|([\s_-]+.)/g, match =>
                match.charAt(match.length - 1).toUpperCase()
            );

        return returnString.replace(/^./, string[0].toLowerCase());
    };

    static debounce(callback: () => void, wait: number): () => void {
        let timeout: number;

        return (...args: []) => {
            // const context = this

            clearTimeout(timeout);

            timeout = window.setTimeout(() => callback.apply(this, args), wait);
        };
    }

    static throttle(func: () => void, timeFrame: number): () => void {
        let lastTime = 0;

        return () => {
            // TS can't coerce Date to number
            const now = new Date() as unknown as number;

            if (now - lastTime >= timeFrame) {
                func();

                lastTime = now;
            }
        };
    }

    static formatPhoneNumber = (phoneNo: string) => {
        const x = phoneNo
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        if (!x) {
            return '';
        }

        const formattedPhoneNumber = !x[2]
            ? x[1]
            : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        return formattedPhoneNumber;
    };

    static scrollToElementId = (elementId: string, buffer?: number): void => {
        const navBar = document.getElementsByClassName('navbar');
        const firstNavBarItem = navBar[0];

        // offsetHeight exists only on HTMLElement, does not exists on SVGElement
        if (firstNavBarItem instanceof HTMLElement) {
            const navBarOffset = firstNavBarItem.offsetHeight;

            const bodyRect = document.body
                ? document.body.getBoundingClientRect().top
                : 0;
            const element = document.getElementById(elementId);

            if (element !== null) {
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = buffer
                    ? elementPosition - navBarOffset - buffer
                    : elementPosition - navBarOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            } else {
                console.warn(`Element with id '${elementId}' not found.`);
            }
        } else {
            console.warn(`Navbar element not found.`);
        }
    };

    static numberWithCommas = (number: number) =>
        number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    static screens = (display?: Display): string => {
        if (typeof display === 'undefined') {
            return '';
        }

        let css = '';

        const ownProps = Object.keys(display);

        let i = ownProps.length;

        const entries = new Array(i);

        while (i--) {
            entries[i] = [ownProps[i], display[ownProps[i]]];
        }

        entries.forEach(value => {
            css += `display-for--${value.join('-')} `;
        });

        return css;
    };

    static getKeyWithLargestValue = (
        object: Record<string, number | string>
    ): boolean | string => {
        if (typeof object === 'object') {
            const objectKeys = Object.keys(object);

            return objectKeys.reduce(
                (accumulator: string, key: string): string =>
                    object[key] >= object[accumulator] ? key : accumulator,
                objectKeys[0]
            );
        }

        return false;
    };

    static isBrowser() {
        return typeof window !== 'undefined';
    }

    static handleThirdPartyLoad(cb: () => void, checkIsLoaded: () => boolean) {
        if (checkIsLoaded()) {
            cb();
        } else {
            setTimeout(() => {
                UtilsService.handleThirdPartyLoad(cb, checkIsLoaded);
            }, 200);
        }
    }

    static shuffle = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    static handleFullStory() {
        if (!window.FS) return;
        import('./ABTestToolService.v2').then(
            ({ default: abTestToolService }: any) => {
                const fullStoryRecordingUrlList = [
                    '/',
                    '/getstarted',
                    '/venue-management-software',
                    '/software-for-florists',
                    '/catering-management-software',
                    '/pricing',
                    '/software-for-event-planners',
                    '/crm-for-photographers',
                    '/features',
                    '/all-in-one-business-platform',
                    '/online-invoices',
                    '/online-contract',
                    '/online-payment-software',
                    '/proposal-software',
                    '/industry-photographers',
                    '/industry-caterers',
                    '/invites',
                    '/templates',
                    '/tools/self-employment-tax-calculator',
                    '/get-document-signing',
                    '/get-started-crm',
                    '/adobe'
                ];
                if (
                    fullStoryRecordingUrlList.includes(window.location.pathname)
                ) {
                    window.FS.restart();
                    const abTests = abTestToolService.getUserPageTests(
                        window.location.pathname
                    );

                    if (abTests) {
                        const tests = Object.values(abTests);
                        tests.forEach(test => window.FS.event('abTest', test));
                    }
                }
            }
        );
    }

    static getCurrentUrl(): string {
        return window.location.origin + window.location.pathname;
    }

    static getCurrentPathname(): string {
        return window.location.pathname;
    }

    static addAdditionalDataToClickStartTrial(
        data: TFeaturesCheckListAnalyticsData,
        module: TAdditionalDataForClickStartTrialModules
    ) {
        const additionalData =
            StorageService.getItem(
                CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
                'sessionStorage'
            ) || {};

        additionalData[window.location.pathname] =
            additionalData[window.location.pathname] || {};
        additionalData[window.location.pathname][module] = data;
        StorageService.setItem({
            key: CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
            data: additionalData,
            storageType: 'sessionStorage'
        });
    }

    private static getUrlInstance(path: string): URL {
        return new URL(path);
    }

    static getUrlPathname(path: string) {
        if (!path) {
            return undefined;
        }
        const url = this.getUrlInstance(path);
        return url.pathname;
    }

    static getOsData() {
        let os = 'unknown';
        let osVersion = 'unknown';
        const ua = navigator.userAgent;

        for (const currentOs of osData) {
            if (currentOs.regex.test(ua)) {
                os = currentOs.os;
                break;
            }
        }

        if (/Windows/.test(os)) {
            const regexResultForOsVersion = /Windows (.*)/.exec(ua);
            osVersion = regexResultForOsVersion?.[1] ?? osVersion;
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS':
            case 'Mac OS X':
                const regexResultForOsVersion =
                    /(?:Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
                        ua
                    );
                osVersion = regexResultForOsVersion?.[1] ?? osVersion;
                break;
            case 'Android':
                osVersion = DeviceService.getMobileOs()
                    .android_version as string;
                break;
            case 'iOS':
                osVersion = DeviceService.getMobileOs().ios_version as string;
                break;
        }
        osVersion = osVersion.replace(/_/g, '.');
        return { $os: os, os_version: osVersion };
    }

    static extractDomain(path: string) {
        if (!path) {
            return undefined;
        }
        const url = this.getUrlInstance(path);
        return url.hostname;
    }

    static getScreenResolution(): string {
        if (UtilsService.isBrowser()) {
            const width = window.screen.width * window.devicePixelRatio;
            const height = window.screen.height * window.devicePixelRatio;
            return `${Math.min(width, height)}x${Math.max(width, height)}`;
        }
        return 'N/A';
    }

    static getPriceAfterDiscount(
        price: number,
        discountValue = 0,
        fixedPrice = 0,
        fractionDigits = 2
    ) {
        if (fixedPrice > 0) {
            return fixedPrice;
        }
        if (discountValue <= 0) {
            return price;
        }
        let result = +(price - (discountValue / 100) * price);
        if (result % 1 !== 0) {
            return result.toFixed(fractionDigits);
        }
        return result;
    }

    static validateReferrer() {
        const { lastUpdated } =
            StorageService.getItem(
                CURRENT_REFERER_STORAGE_KEY,
                'sessionStorage'
            ) || {};
        if (Date.now() - lastUpdated > 1000 * 60 * 60) {
            StorageService.removeItem(
                CURRENT_REFERER_STORAGE_KEY,
                'sessionStorage'
            );
        }
    }

    static updateReferrer() {
        //help us indicate our current referrer because this code runs before we navigate to new url
        StorageService.setItem({
            key: CURRENT_REFERER_STORAGE_KEY,
            data: {
                referrer: this.getCurrentUrl(),
                lastUpdated: Date.now()
            },
            storageType: 'sessionStorage'
        });
    }

    static shouldUseABTestToolV2() {
        return true;
    }

    static MEDIUM_SCREEN_BREAKPOINT = '768px';

    static isInTemplatePreviewPage() {
        return /\/template[-_]preview/.test(window.location.pathname);
    }
}

export const breakPoints = {
    medium: 768,
    large: 992,
    xlarge: 1200
};
